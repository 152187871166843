export const LandscapeImages = [
    
  "https://ik.imagekit.io/jackthibodeau/Photography_/Landscape/7F8A0495_uKnPtBIl6.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1642632856304",
  "https://ik.imagekit.io/jackthibodeau/Photography_/Landscape/7F8A0549_yMSLUuFwdAB.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1642632856277",
  "https://ik.imagekit.io/jackthibodeau/Photography_/Landscape/7-12-15-6_dyYd6eeax.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1642632855240",
  "https://ik.imagekit.io/jackthibodeau/Photography_/Landscape/7-12-15-4_2Kd5z3Ac5.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1642632855264",
  "https://ik.imagekit.io/jackthibodeau/Photography_/Landscape/7F8A0469_uSl4qzN_n.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1642632855675",
  "https://ik.imagekit.io/jackthibodeau/Photography_/Landscape/7F8A0241bw_r__Hc3-r9xc.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1642632855422",
  "https://ik.imagekit.io/jackthibodeau/Photography_/Landscape/snodgrassfall_JvVTtPSVZrYK.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1642632857671",
  "https://ik.imagekit.io/jackthibodeau/Photography_/Landscape/pyramidpeak_working_SkOJRSwb5.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1642632857292",
  "https://ik.imagekit.io/jackthibodeau/Photography_/Landscape/lunar1__mSleHiYSed.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1642632857284",
 
    

  ];