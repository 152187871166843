import "./styles.css";
import React from "react";
import Gallery from "react-photo-gallery";
import RampageGallery from "./RampageGallery";
import StudioGallery from "./studioPhotography";
import LandscapeGallery from "./landscapeGallery";
import { Row, Col, Image, Container, MenuItem } from "react-bootstrap";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import FilmGallery from "./FilmGallery";

function FadeInSection(props) {
  const [isVisible, setVisible] = React.useState(false);
  const domRef = React.useRef();
  React.useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => setVisible(entry.isIntersecting));
    });
    observer.observe(domRef.current);
  }, []);
  return (
    <Col
      xs={12}
      className={`  fade-in-section ${isVisible ? "is-visible" : ""}`}
      ref={domRef}
    >
      {props.children}
    </Col>
  );
}

function photography2() {
  return (
    <div style={{ marginTop: "2rem" }}>
      <Container fluid>
        <FadeInSection>
          <h1 className="customFont recent">Studio</h1>
        </FadeInSection>
      </Container>

      <StudioGallery />
      <Container fluid>
        <FadeInSection>
          <h1 className="customFont recent">Landscape</h1>
        </FadeInSection>
      </Container>

      <LandscapeGallery />

      <Container fluid>
        <FadeInSection>
          <h1 className="customFont recent">Mountain Biking</h1>
        </FadeInSection>
      </Container>

      <RampageGallery />
      <FadeInSection>
        <Container fluid>
          <h1 className="customFont recent">Film</h1>
        </Container>
      </FadeInSection>
      <FilmGallery />
    </div>
  );
}

export default photography2;
