export const printMakingImages = [
  
  "https://ik.imagekit.io/jackthibodeau/PrintMaking/druggedPizza_w_0Rw3phqa.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1642618937895",
  "https://ik.imagekit.io/jackthibodeau/PrintMaking/skullgirlScreenprint_HOCjjanLxqO.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1642618937715",
  "https://ik.imagekit.io/jackthibodeau/PrintMaking/shredUntilyourDead_iiXPn3qya.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1642618937231",
  "https://ik.imagekit.io/jackthibodeau/PrintMaking/heartinajar_zswdNj6l7s2E.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1642618938002",
  "https://ik.imagekit.io/jackthibodeau/PrintMaking/thumbsinajar_1IJ2eZ6UyN.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1642618937943",
  "https://ik.imagekit.io/jackthibodeau/PrintMaking/liverinajar_Hwdm3I4GKL3Y.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1642618938004",

  

  
];
