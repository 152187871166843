import './styles.css';
import React from "react";
import { Row, Col, Image, Container, MenuItem } from "react-bootstrap";
import Pdf from './resumeComp';

function Resume() {
 return (
    <div style={{ marginBottom: "10rem", marginTop: "2rem" }}>
      <Pdf/>
    </div>
  );
}


export default Resume;