export const StudioImages = [
    
    "https://ik.imagekit.io/jackthibodeau/Photography_/covidhartmans-84_EUVOg5LIMT.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1642709678665",
    "https://ik.imagekit.io/jackthibodeau/Photography_/covidhartmans-83_TDzTCe6FI_h.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1642709678700",
    "https://ik.imagekit.io/jackthibodeau/Photography_/covidhartmans-82_aELpp4nUb.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1642709678506",
    "https://ik.imagekit.io/jackthibodeau/Photography_/STUDIO/slicedlemon1_-bmA9Pj-C.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1642632222442",
    "https://ik.imagekit.io/jackthibodeau/Photography_/STUDIO/slicedlemon3_9d1NL6OwM1s.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1642632223228",
    "https://ik.imagekit.io/jackthibodeau/Photography_/STUDIO/slicedlemon2_UQoJhJvBL.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1642632222802",
    "https://ik.imagekit.io/jackthibodeau/Photography_/STUDIO/11_vhwlGy974_nAQRHe4_p.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1642635547659",
    "https://ik.imagekit.io/jackthibodeau/Photography_/STUDIO/7_loul1wZhk2_F13UYpWX7WG.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1642635547449",
    "https://ik.imagekit.io/jackthibodeau/Photography_/STUDIO/4_69hNY-cZ6_NKPODfDPaaT5.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1642635548079",
    "https://ik.imagekit.io/jackthibodeau/Photography_/STUDIO/2_QyYlQZ21v_QFkWkklgMB.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1642635547948",
    "https://ik.imagekit.io/jackthibodeau/Photography_/STUDIO/9_EmlEFk73I_Cx5LU_1JF.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1642635547525",
    "https://ik.imagekit.io/jackthibodeau/Photography_/STUDIO/3_fIkNO5OT9_2R2CwhQOy.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1642635548579",
    "https://ik.imagekit.io/jackthibodeau/Photography_/STUDIO/candy1_vbvFKwhM2.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1642713567107",
    "https://ik.imagekit.io/jackthibodeau/Photography_/STUDIO/popcorn3_RmvjVcfgF.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1642713567707",
    "https://ik.imagekit.io/jackthibodeau/Photography_/STUDIO/soda1_iMUfodZlc.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1642713569320",
    "https://ik.imagekit.io/jackthibodeau/Photography_/STUDIO/razorportFinal_Dt8rW57Ia7.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1642713569056",
    "https://ik.imagekit.io/jackthibodeau/Photography_/STUDIO/ropeportfinal_KT6OT4Yq1m.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1642713569742",
    "https://ik.imagekit.io/jackthibodeau/Photography_/STUDIO/gunPortFinal_58qP4bx_Ltma.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1642713569103",
    "https://ik.imagekit.io/jackthibodeau/Photography_/STUDIO/gun2_3MiUXRXM9.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1642713568915",
    "https://ik.imagekit.io/jackthibodeau/Photography_/STUDIO/rope2_zS0agmGhk.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1642713568819",
    "https://ik.imagekit.io/jackthibodeau/Photography_/STUDIO/pill1_sS62zcfvxhJ.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1642713567109",
    "https://ik.imagekit.io/jackthibodeau/Photography_/STUDIO/portfolio10_Z5DezuOZJ.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1642632220404",
    "https://ik.imagekit.io/jackthibodeau/Photography_/STUDIO/portfolio9_zcKe0bK4u.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1642632220058",
    "https://ik.imagekit.io/jackthibodeau/Photography_/STUDIO/porfolio5_aQXlYwt0p0S.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1642632219378",
    "https://ik.imagekit.io/jackthibodeau/Photography_/STUDIO/peper2_4CiiSFV1w.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1642632218490",
    "https://ik.imagekit.io/jackthibodeau/Photography_/STUDIO/portfolio2_i9p9C5aucL.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1642632219760",
    "https://ik.imagekit.io/jackthibodeau/Photography_/STUDIO/potfolio1_sYTmWjZeU.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1642632220677",
    "https://ik.imagekit.io/jackthibodeau/Photography_/STUDIO/beercan1_7RDRm9wt4.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1642632215455",
    "https://ik.imagekit.io/jackthibodeau/Photography_/STUDIO/beercan2_rRHLFBhRA.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1642632215708",
    ,"https://ik.imagekit.io/jackthibodeau/Photography_/STUDIO/pinkvans_-ea9Zvrwk.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1642632219390",
    
    

  ];