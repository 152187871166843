import './styles.css';
import React from "react";
import pic from "./jackj.jpeg"
import Contact from "./contact"
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

function About2() {
  return (
    
      <div>
        <img className="aboutPic" src={pic} height="400rem" />
        <div className="aboutText" style={{marginBottom:"100px"}}>
            <h1>About</h1>
            <p>Jack Thibodeau is a graphic designer, photographer, web developer, and artist. Adapting to any new situation, from adventure sports to different artistic mediums and styles, is where Jack thrives the most. His outside the box thinking creates innovative images and composition. Experimenting with and pushing each medium to new limits, shows Jack's drive to continually make his art, and himself, the best it can be. His knowledge of color theory, dedication to film photography, and computer science background inspires inventive and contemporary style. Equipped with his work ethic and authentic style, Jack's graphic designs are wholly original. </p>
        </div>
        <h1 className="aboutText">Contact</h1>
        <Contact/>
      </div>
 
  );
}


export default About2;