export const paintingImages = [
  "https://ik.imagekit.io/jackthibodeau/Painting/jellyfish_lXfVOLCmw.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1642621393461",
  "https://ik.imagekit.io/jackthibodeau/Painting/dumboOcto_W4xGhNmao.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1642621393106",
  "https://ik.imagekit.io/jackthibodeau/Painting/killerwhale_SjnEEVVcLk9.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1642621393678",
  "https://ik.imagekit.io/jackthibodeau/Painting/octopus_3odHc0Kzio.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1642621393948",
  "https://ik.imagekit.io/jackthibodeau/Painting/bluecrab_49By-1MhE.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1642621392927",
  "https://ik.imagekit.io/jackthibodeau/Painting/eggontoast_PQqGACm5Ak6.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1642621392983",
  "https://ik.imagekit.io/jackthibodeau/Painting/skeletonmuose_5C_VddBg6.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1642621394401",

  "https://ik.imagekit.io/jackthibodeau/Painting/popsicle_LrlwBMKXeUtv.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1642621393905",
  
  "https://ik.imagekit.io/jackthibodeau/Painting/lollipop_yb3KOqxgOt.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1642621393744",
  "https://ik.imagekit.io/jackthibodeau/Painting/suculantskull_oFbzlMgBnpN.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1642621394614",
  "https://ik.imagekit.io/jackthibodeau/Painting/covidhartmans-7_XrJkTeMKT.jpg?updatedAt=1636488171252",

  "https://ik.imagekit.io/jackthibodeau/Painting/shoepainting_QlVOIpUrp4.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1642621394117",

  "https://ik.imagekit.io/jackthibodeau/Painting/flamingobeer_Kh2-8CXIs.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1642621393267",
];
