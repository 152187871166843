import './styles.css';
import React from "react";
import Gallery from "react-photo-gallery";
import PrintMakingGallery  from "./printMakingGallery";
import { Row, Col, Image, Container, MenuItem } from "react-bootstrap";

function PrintMaking2() {
 return (
    <div style={{ marginBottom: "10rem", marginTop: "2rem" }}>
      <Container fluid>
        <h1 className="customFont recent">PrintMaking</h1>
      </Container>
      <PrintMakingGallery />
    </div>
  );
}


export default PrintMaking2;