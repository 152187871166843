export const rampageImages = [
    
    "https://ik.imagekit.io/jackthibodeau/Photography_/action/sequence2_KeVEXn9xAXuv.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1642632416245",
    "https://ik.imagekit.io/jackthibodeau/Photography_/action/7F8A6217_7EvgxVz8dA.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1642632415065",
    "https://ik.imagekit.io/jackthibodeau/Photography_/action/7F8A2231_QnAANJSHG.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1642632414211",
    "https://ik.imagekit.io/jackthibodeau/Photography_/action/7F8A2116_-AMdYzRU1.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1642632413796",
    "https://ik.imagekit.io/jackthibodeau/Photography_/action/IMG_0046_d5WriiiNn.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1642632415940",
    "https://ik.imagekit.io/jackthibodeau/Photography_/action/7F8A2281_SeSM4802gFG.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1642632414517",
    "https://ik.imagekit.io/jackthibodeau/Photography_/action/7F8A2116_-AMdYzRU1.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1642632413796",
    "https://ik.imagekit.io/jackthibodeau/Photography_/action/7F8A2099_xD2q3TSVv.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1642632413648",
    "https://ik.imagekit.io/jackthibodeau/Photography_/action/7F8A2692_ua7PuR9EHt.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1642632415041",
    "https://ik.imagekit.io/jackthibodeau/Photography_/action/7F8A2260_tnzbrduWM.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1642632414372",
    "https://ik.imagekit.io/jackthibodeau/Photography_/action/barclaydfd_final_1myojw_V8iM.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1642632415321",
    "https://ik.imagekit.io/jackthibodeau/Photography_/action/canyon1_ITY97liKW.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1642632415439",
    

  ];
  