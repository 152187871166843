import React, { useState } from "react";
import { Document, Page } from "react-pdf/dist/esm/entry.parcel";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import { Row, Col, Image, Container, MenuItem } from "react-bootstrap";
import "./styles.css";
function Pdf() {
  return (
    <div>
      <Row>
        <Col className="resume" sm={12} md={10}>
          <Image
            src="https://ik.imagekit.io/jackthibodeau/Artboard_2_eVWoQoLgf.jpg?updatedAt=1639607030134"
            fluid
          />
        </Col>
      </Row>
      <Row>
        <Col className="resume" sm={12} md={10}>
          <Image
            src="https://ik.imagekit.io/jackthibodeau/Artboard_3_ayxHMPq6r.jpg?updatedAt=1639597857416"
            fluid
          />
        </Col>
      </Row>
    </div>
  );
}
export default Pdf;
